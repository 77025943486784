import {MoneyCurrencyInput} from '@thx/controls';
import debug from 'debug';
import type Money from 'js-money';
import {useState} from 'react';
import type {InputProps} from 'semantic-ui-react';
import {useGetDocumentAmountInputDocumentTypeQuery} from './getDocumentAmountInputDocumentType';

const d = debug('tacs.web.documents.components.documents.DocumentAmountInput');

interface DocumentTypeDropdownProps extends Omit<InputProps, 'onChange'> {
	onChange?: (val: Money | null) => void;
	documentTypeId: string;
	disabled: boolean;
}

export function DocumentAmountInput(props: DocumentTypeDropdownProps) {
	const {documentTypeId, loading: propsLoading, setVendorEnabled, onChange, ...rest} = props;
	const [hideAmount, setHideAmount] = useState<boolean>(true);
	const {loading} = useGetDocumentAmountInputDocumentTypeQuery({
		variables: {documentTypeId},
		onCompleted: result => {
			if ((!result?.getDocumentTypeById?.amount || !documentTypeId) && !hideAmount) {
				onChange && onChange(null);
				setHideAmount(true);
			}
			if (result?.getDocumentTypeById?.amount && hideAmount) {
				setHideAmount(false);
			}
		},
	});

	return <MoneyCurrencyInput {...rest} onChange={onChange} disabled={hideAmount || props.disabled} loading={propsLoading || loading} fluid />;
}
